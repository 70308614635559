const DEV = process.env.NODE_ENV === 'development'

export default {
  init() {
    if (window.LIVE_COVERAGE) {
      ///console.log('Live Coverage ON')
      // setInterval(checkLiveUpdates, 360000) // 6 minuti
      setInterval(checkLiveUpdates, 360000)

      this.setupLoadMoreClickHandler() // Inizializza l'event listener per il pulsante "Load More"
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
  setupLoadMoreClickHandler() {
    document.body.addEventListener('click', function (e) {
      // console.log(e.target.id);
      const buttonShowUpdates = e.target.closest('#showUpdatesBtn') // Look for button "show updates"

      if (e.target.classList.contains('js-live-news-load-more')) {
        e.preventDefault()

        console.log('Load More Clicked')

        let button = e.target
        let postId = button.getAttribute('data-post-id')
        let transientId = button.getAttribute('data-transient-id')

        // Nasconde il pulsante dopo il click
        button.style.display = 'none'
        button.parentElement.style.display = 'none'

        let formData = new FormData()
        formData.append('action', 'live_news_load_more_content')
        formData.append('post_id', postId)
        formData.append('transient_id', transientId)
        formData.append('load_more', true)

        var ajax_url = cnArgs.ajaxUrl // URL dell'endpoint AJAX

        fetch(ajax_url, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              document.querySelector('.article-body').innerHTML += data.data
              // fix twitter not rendering correctly
              if (typeof twttr !== 'undefined') {
                twttr.widgets.load()
              } else {
                var script = document.createElement('script')
                script.src = 'https://platform.twitter.com/widgets.js'
                script.async = true
                document.body.appendChild(script)
              }
            } else {
              console.error('Errore:', data.data)
            }
          })
          .catch((error) =>
            console.error('Errore nella richiesta AJAX:', error)
          )
      }

      // load updates
      if (buttonShowUpdates) {
        // add disabled and opacity 0.5 to the clicked element
        // e.target.classList.add('disabled')
        buttonShowUpdates.setAttribute('disabled', 'true')
        buttonShowUpdates.classList.add('opacity-50')
        showUpdates()
      }
    })
  },
}

/**
 * Checks for live updates by fetching the current page's HTML and comparing
 * the '.live-block[data-published]' elements with the ones in the fetched HTML.
 * If new live blocks are found, they are inserted into the '.article-body'
 * of the current page. The '.mux-player-wrapper' divs are preserved and not
 * replaced during this process.
 *
 * @function checkLiveUpdates
 * @returns {void}
 */
let newBlocks
let currentBlocks
function checkLiveUpdates() {
  console.log('Checking for live updates...')

  // recuperiamo il post-id, sfruttando l'id dell'article
  let primarySection = document.querySelector('#primary')
  let postId
  if (primarySection) {
    let article = primarySection.querySelector("article[id^='post-']")
    if (article) {
      postId = article.id.replace('post-', '')
    }
  }

  let formData = new FormData()

  formData.append('action', 'live_news_load_more_content')
  formData.append('post_id', postId)
  formData.append('transient_id', 1)
  formData.append('load_more', false)

  // var ajax_url = lgc_global['ajaxurl'];
  var ajax_url = cnArgs.ajaxUrl

  fetch(ajax_url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((html) => {
      // console.log(html);
      // console.log(html.data);
      const parser = new DOMParser()
      const doc = parser.parseFromString(html.data, 'text/html')
      newBlocks = doc.querySelectorAll('.live-block[data-published]')
      currentBlocks = document.querySelectorAll('.live-block[data-published]')
      const currentPublishedDates = Array.from(currentBlocks).map((block) =>
        block.getAttribute('data-published')
      )
      let updatesCount = 0
      newBlocks.forEach((newBlock) => {
        const newPublishedDate = newBlock.getAttribute('data-published')
        if (!currentPublishedDates.includes(newPublishedDate)) {
          updatesCount++
        }
      })
      if (updatesCount > 0) {
        document.getElementById('updates-number').textContent = updatesCount
        document
          .querySelectorAll('.updates-notification')
          .forEach((element) => {
            element.classList.remove('hidden')
          })
      }
    })
    .catch((error) => console.error('Error fetching live updates:', error))
}

function showUpdates() {
  console.log('Showing updates...')
  document.querySelectorAll('.updates-notification').forEach((element) => {
    element.classList.add('hidden')
  })
  // window.scrollTo({
  //   top: document.querySelector('#start-content').offsetTop + 200,
  //   behavior: 'smooth',
  // })

  let primarySection = document.querySelector('#primary')
  let postId = false
  if (primarySection) {
    let article = primarySection.querySelector("article[id^='post-']")
    if (article) {
      postId = article.id.replace('post-', '')
    }
  }
  let formData = new FormData()

  formData.append('action', 'live_news_load_more_content')
  formData.append('post_id', postId)
  formData.append('transient_id', 1)

  // var ajax_url = lgc_global['ajaxurl'];
  var ajax_url = cnArgs.ajaxUrl

  // prepare a container to be added in top
  const recentUpdatesContainer = document.createDocumentFragment()

  fetch(ajax_url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((html) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html.data, 'text/html')
      newBlocks = doc.querySelectorAll('.live-block[data-published]')
      currentBlocks = document.querySelectorAll('.live-block[data-published]')
      const currentPublishedDates = Array.from(currentBlocks).map((block) =>
        block.getAttribute('data-published')
      )
      let updatesCount = 0
      let firstBlockId = '#start-content'
      newBlocks.forEach((newBlock) => {
        const newPublishedDate = newBlock.getAttribute('data-published')
        if (!currentPublishedDates.includes(newPublishedDate)) {
          updatesCount++
          //console.log('found something new')
          if (document.querySelector('.live-block')) {
            if (newBlock.dataset.forceReload === 'true') {
              forceReload()
              return
            }
            let nextSibling = newBlock.nextElementSibling
            const fragment = document.createDocumentFragment()
            let blockAppend = newBlock.cloneNode(true)
            // check for data url, fix when ajax loading
            blockAppend
              .querySelectorAll('.copy-url-at')
              .forEach(function (button) {
                let liveBlock = button.closest('.live-block')

                if (liveBlock && liveBlock.id) {
                  let blockId = liveBlock.id

                  // Prende l'URL corrente senza parametri
                  let cleanUrl =
                    window.location.origin + window.location.pathname

                  // Costruisce l'URL con l'ID
                  let finalUrl = `${cleanUrl}#${blockId}`
                  button.setAttribute('data-url', finalUrl)
                }
              })

            // fragment.appendChild(newBlock.cloneNode(true))
            fragment.appendChild(blockAppend)

            // prendiamo id del primo blocco aggiunto
            if (updatesCount === 1) {
              firstBlockId = '#' + blockAppend.id
            }

            while (
              nextSibling &&
              !nextSibling.classList.contains('live-block')
            ) {
              fragment.appendChild(nextSibling.cloneNode(true))
              nextSibling = nextSibling.nextElementSibling
            }

            recentUpdatesContainer.appendChild(fragment)
          } else {
            forceReload()
            // const newArticleBody = doc.querySelector('.article-body')
            // const currentArticleBody = document.querySelector('.article-body')

            // if (newArticleBody && currentArticleBody) {
            //   const newNodes = Array.from(newArticleBody.childNodes)
            //   const originalMuxPlayers = Array.from(
            //     currentArticleBody.querySelectorAll('.mux-player-wrapper')
            //   )
            //   currentArticleBody.innerHTML = ''

            //   newNodes.forEach((node) => {
            //     if (
            //       node.classList &&
            //       node.classList.contains('mux-player-wrapper')
            //     ) {
            //       const originalNode = originalMuxPlayers.shift()
            //       if (originalNode) {
            //         currentArticleBody.appendChild(originalNode)
            //       }
            //     } else {
            //       currentArticleBody.appendChild(node.cloneNode(true))
            //     }
            //   })
            //}
          }

          //riattivare click sui links

          // newBlock.classList.add('new-live-block')
          // setTimeout(() => {
          //   newBlock.classList.remove('new-live-block')
          // }, 1000)
        }
      })
      if (updatesCount > 0) {
        document
          .querySelector('.article-body')
          .insertBefore(
            recentUpdatesContainer,
            document.querySelector('.live-block')
          )

        // remove opacity
        document
          .getElementById('showUpdatesBtn')
          .setAttribute('disabled', 'false')
        // document.getElementById('showUpdatesBtn').classList.remove('disabled')
        document.getElementById('showUpdatesBtn').classList.remove('opacity-50')

        if (typeof twttr !== 'undefined') {
          twttr.widgets.load()
        }

        window.scrollTo({
          // top: document.querySelector('#start-content').offsetTop,
          top: document.querySelector(firstBlockId).offsetTop - 200,
          behavior: 'smooth',
        })
      }
    })
    .catch((error) => console.error('Error fetching live updates:', error))
}

//document.getElementById('showUpdatesBtn').addEventListener('click', showUpdates)

function forceReload() {
  window.location.reload(true)
}
